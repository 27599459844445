import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import CenterBreadCrumbs from "../../components/centerBreadCrumbs"

import Image from "../../components/image"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "lilseeds/1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "lilseeds/2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThree: file(relativePath: { eq: "lilseeds/3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFour: file(relativePath: { eq: "lilseeds/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFive: file(relativePath: { eq: "lilseeds/5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSix: file(relativePath: { eq: "lilseeds/6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSeven: file(relativePath: { eq: "lilseeds/7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageEight: file(relativePath: { eq: "lilseeds/8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageNine: file(relativePath: { eq: "lilseeds/9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTen: file(relativePath: { eq: "lilseeds/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageEleven: file(relativePath: { eq: "lilseeds/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwelve: file(relativePath: { eq: "lilseeds/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageThirteen: file(relativePath: { eq: "lilseeds/13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFourteen: file(relativePath: { eq: "lilseeds/14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageFifteen: file(relativePath: { eq: "lilseeds/15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSixteen: file(relativePath: { eq: "lilseeds/16.png" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageSeventeen: file(relativePath: { eq: "lilseeds/17.png" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageEighteen: file(relativePath: { eq: "lilseeds/18.png" }) {
        childImageSharp {
          fluid(maxWidth: 410) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const galleryImages = [
    {
      image: data.imageThree.childImageSharp.fluid,
      caption: `Exploring the texture of soil, while planting veggies in our garden..`,
    },
    {
      image: data.imageFour.childImageSharp.fluid,
      caption: `The beauty of a monarch butterfly.`,
    },

    {
      image: data.imageNine.childImageSharp.fluid,
      caption: `Spatial Awareness, developing an understanding about construction theories.`,
    },
    {
      image: data.imageTen.childImageSharp.fluid,
      caption: `How tall is this tree?`,
    },
    {
      image: data.imageEleven.childImageSharp.fluid,
      caption: `There are big slimy eels down there…..`,
    },

    {
      image: data.imageFourteen.childImageSharp.fluid,
      caption: `So many plums to pick….`,
    },
  ]

  return (
    <>
      <SEO title="Lil Seeds - Gallery" />

      <CenterBreadCrumbs
        centerName="Lil Seeds"
        centerUrl="/littleseeds/"
        subPageName="Gallery"
      />

      <div class="container-fluid horizontal-section-container clearfix">
        <div class="row">
          <div class="col-sm-12">
            <div
              id="main-page-content"
              class="section-container main-page-content clearfix"
            >
              <div class="section-content">
                <h1 class="page_title">Gallery</h1>

                <div id="content">
                  <div class="thumbnail-list row clearfix">
                    {galleryImages.map(galleryImage => {
                      return (
                        <div class="item col-sm-4">
                          <div class="thumbnail item-content">
                            <Image image={galleryImage.image} />
                            <div class="caption">
                              <p>{galleryImage.caption}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallery
